import React from 'react';
import Ad from './Ad';
import { useStaticAds } from './useStaticAds';

export default function DesktopAds(props) {
  const { site, allWpAd } = useStaticAds();
  const { display } = props;

  const arrAds = allWpAd.edges;
  const terms = site.siteMetadata.tag;

  const ads = arrAds.filter(v => {
    let arr = v.node.pageads.tag?.map(a => a.name);
    return arr?.some(r => terms.indexOf(r) >= 0);
  }); 

  return (
    <div className="ads text-center text-md-left p-4 p-md-4 p-xl-0">
      {ads.map((ad) => <Ad display={display} mobile={ad.node.pageads.mobil} desktop={ad.node.pageads.desktop} key={ad.node.databaseId} link={ad.node.pageads.lank} />)}
    </div>    
  );
}

