import React from 'react';
import Img from "gatsby-image";

export default function Ad(props) {

    const { link, mobile, desktop, display } = props;

    let desktopCss = "d-none d-lg-block";
    let adRow = "ad";
    if (display === "mobile") {
        desktopCss = "d-none";
        adRow = "ad d-lg-none";
    }

    let mobileCss = "d-block d-md-none";
    if (display === "desktop") {
        mobileCss = "d-none";
        adRow = "ad d-none d-lg-block";
    }

    return (
        <div className={adRow}>
            <div className="row">
                <div className="col-6 text-left ad-text">– ANNONS –</div>
                <div className="col-6 text-right ad-text">– ANNONS –</div>
            </div>
            <a href={link} target="_blank" rel="noreferrer">

                <div className={desktopCss}>
                    <img src={desktop.localFile.publicURL} alt="" />
                </div>

                <div className={mobileCss}>
                    <img src={mobile.localFile.publicURL} alt="" />
                </div>

            </a>
            <hr />
        </div>
    )
}