import { useStaticQuery, graphql } from "gatsby"
export const useStaticAds = () => {
    const { site, allWpAd } = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    siteMetadata {
                        tag
                    }
                },
                allWpAd {
                    edges {
                        node {
                            databaseId
                            pageads {
                                lank
                                tag {
                                    name
                                }
                                desktop {
                                    localFile {
                                        publicURL, 
                                    }
                                },
                                mobil {
                                    localFile {
                                        publicURL, 
                                    
                                    }
                                }
                            }
                        }
                    }
                }
                    
            }
            `
    )
    return {site, allWpAd};
}
