import React from 'react';
import Ad from './Ad';
import {useStaticAds} from './useStaticAds';

export default function MobileAds(props) {
    const {site, allWpAd} = useStaticAds();
    const {select, turn} = props;
    
    const arrAds = allWpAd.edges;
    const terms = site.siteMetadata.tag;

    const ads = arrAds.filter(v => {
        let arr = v.node.pageads.tag?.map(a => a.name);
        return arr?.some(r => terms.indexOf(r) >= 0);
    });
    if(ads <= 0) {
      return null;
    }
    
    const ad = ads[select];
    let desktop = ad.node.pageads.desktop;
    let mobile = ad.node.pageads.mobil;
    if(turn) {
      let s = desktop;
      mobile = desktop;
      mobile = s;
    }
    
    return (
        <div className="ads text-center text-md-left p-4 p-md-4 p-xl-0">
            <Ad display="mobile" mobile={mobile} desktop={desktop} key={ad.node.databaseId} link={ad.node.pageads.lank} />
        </div>
    );
}

